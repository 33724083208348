
import { defineComponent } from 'vue'
import { Item } from '@/dto/Item'
import { randomFileServer } from '@/services/API'
import { ItemType } from '@/dto/enums/ItemType'
import * as router from '@/router'
import { logDetailClick } from '@/services/EventLogger'

export default defineComponent({
  props: {
    item: {
      type: Item,
      required: true
    },
    useSmallColumns: {
      type: Boolean,
      default: true
    }
  },

  setup() {
    return { ItemType }
  },

  methods: {
    selectItem() {
      logDetailClick()
      router.default.push({ name: router.routeNames.ITEM, params: { id: this.item.id } })
    }
  },

  computed: {
    fileURL(): string {
      const fs = randomFileServer()
      if (fs) {
        // GIF
        if (this.item.type === ItemType.Gif && this.item.animatedThumbUrl) {
          return fs.concat(this.item.animatedThumbUrl)
        }

        // Image or Video
        if ((this.item.type === ItemType.Image || this.item.type === ItemType.Video) && this.item.thumbUrl) {
          return fs.concat(this.item.thumbUrl)
        }

        // Fallback
        if (this.item.type !== ItemType.Video) {
          return fs.concat(this.item.url)
        }

        return ''
      }

      return ''
    },

    gridHeight() {
      const hmargin = 5

      const width = this.useSmallColumns ? 168 : 232
      const rowheight = 10

      const itemRatio: number = this.item.ratio
      const neededHeight = width * itemRatio + (hmargin * 2)
      const neededRows = Math.floor(neededHeight / rowheight)

      return {
        'grid-row-end': 'span ' + neededRows
      }
    }
  }
})
