import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3056424c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feedContainer" }
const _hoisted_2 = {
  key: 0,
  id: "fader"
}
const _hoisted_3 = { id: "download-app-area" }
const _hoisted_4 = { id: "download-app-header" }
const _hoisted_5 = { id: "download-app-button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feed_item_component = _resolveComponent("feed-item-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_feed_item_component, {
        key: item.id,
        item: item,
        useSmallColumns: _ctx.useSmallColumns
      }, null, 8, ["item", "useSmallColumns"]))
    }), 128)),
    (_ctx.items && _ctx.items.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('see-more')), 1),
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openApp && _ctx.openApp(...args))),
              id: "download-app-button"
            }, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('download-app')), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}