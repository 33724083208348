
import { defineComponent, PropType, ref } from 'vue'
import { Item } from '@/dto/Item'
import FeedItemComponent from '@/views/components/feed/FeedItemComponent.vue'
import { useI18n } from 'vue-i18n'
import * as router from '@/router'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<Item>>,
      default: [] as PropType<Array<Item>>
    }
  },

  components: {
    FeedItemComponent
  },

  methods: {
    changeCellWidth() {
      this.useSmallColumns = (window.innerWidth < 768)
    },

    openApp() {
      router.default.push({ name: router.routeNames.DOWNLOAD })
    }
  },

  setup() {
    const useSmallColumns = ref(window.innerWidth < 768)

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { useSmallColumns, t }
  },

  mounted() {
    window.addEventListener('resize', this.changeCellWidth)
  },

  unmounted() {
    window.removeEventListener('resize', this.changeCellWidth)
  }
})
